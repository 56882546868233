@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700;900&display=swap");
/************************************* Reset Styles ********************************/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td, div {
  vertical-align: top;
}

* {
  outline: none;
}

li, ol {
  list-style-type: none;
  list-style-image: none;
  list-style: none;
}

img {
  border: 0px;
}

textarea {
  resize: none;
}

input:focus, select:focus, textarea:focus, button:focus {
  outline: none;
}

a {
  text-decoration: none;
}

input, textarea, select, body {
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}

.safeArea {
  max-width: 1350px;
  margin: 0 auto;
}

.clearBoth {
  clear: both;
}

/************************************* Reset End Styles ********************************/
.headerBar {
  background-color: #fe6a20;
}
.headerBar .safeArea {
  position: relative;
}
.headerBar .leftCol {
  width: 50%;
  text-align: left;
  float: left;
  padding: 15px 25px;
  color: #ffffff;
  font-size: 14px;
}
.headerBar .requestCallBack {
  width: 50%;
  text-align: right;
  float: right;
}
.headerBar .requestCallBack a {
  padding: 15px 25px;
  display: inline-block;
  background-color: #ffffff;
  color: #697c89;
  font-weight: 500;
  font-size: 15px;
  transition: all cubic-bezier(0.53, 0.39, 0.12, 0.81) 200ms;
}
.headerBar .requestCallBack a:hover {
  background-color: #fe6a20;
  color: #ffffff;
}

.callbackPopup {
  position: absolute;
  right: 0;
  top: 100%;
  width: 40%;
  background-color: #ffffff;
  display: none;
}
.callbackPopup .popTitle {
  padding: 15px;
  font-size: 20px;
  font-weight: 500;
  color: #000000;
  border-bottom: 1px solid #000000;
  position: relative;
  display: none;
}
.callbackPopup .popTitle .close {
  width: 30px;
  height: 30px;
  float: right;
}
.callbackPopup .popTitle .close img {
  width: 100%;
}
.callbackPopup .popBody {
  padding: 15px;
}

.mainBannerHolder {
  text-align: center;
  background-position: right center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 80px 0;
}
.mainBannerHolder .logoHolder {
  width: 180px;
  display: inline-block;
  margin-bottom: 50px;
}
.mainBannerHolder .captions .smallText {
  font-size: 22px;
  font-weight: 300;
  margin-bottom: 15px;
  color: #ffffff;
  text-transform: uppercase;
}
.mainBannerHolder .captions .bigText {
  font-size: 46px;
  font-weight: 900;
  color: #ffffff;
  text-transform: uppercase;
  margin-bottom: 25px;
}
.mainBannerHolder .captions .bigText span {
  color: #fe6a20;
}

.hpBlock {
  padding: 80px 0;
  background-color: #f2f2f2;
}
.hpBlock .blockTitle {
  text-align: center;
  margin-bottom: 55px;
}
.hpBlock .blockTitle .smallText {
  font-size: 19px;
  color: #ffffff;
  font-weight: 400;
  margin-bottom: 10px;
  text-transform: uppercase;
}
.hpBlock .blockTitle h2 {
  font-size: 30px;
  color: #000;
  font-weight: 900;
  margin-bottom: 25px;
  position: relative;
  padding-bottom: 15px;
  text-transform: uppercase;
}
.hpBlock .blockTitle h2 span {
  color: #fe6a20;
}
.hpBlock .blockTitle h2::after {
  content: "";
  width: 150px;
  height: 3px;
  background-color: #fe6a20;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
}
.hpBlock .blockTitle .blockText {
  width: 700px;
  margin: 0 auto;
}

.prodList ul li {
  font-size: 0;
  margin-bottom: 40px;
}
.prodList ul li .prodImg {
  width: 45%;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5%;
  position: relative;
}
.prodList ul li .prodImg::after {
  content: "";
  width: 100px;
  height: 100px;
  position: absolute;
  right: 0;
  bottom: 0;
  background-image: url(../images/pattern-1.svg);
  background-repeat: no-repeat;
  background-size: 100% auto;
  z-index: 1;
}
.prodList ul li .prodImg img {
  width: 100%;
  position: relative;
  z-index: 2;
}
.prodList ul li .prodText {
  width: 50%;
  display: inline-block;
  vertical-align: middle;
}
.prodList ul li .prodText h3 {
  font-size: 24px;
  font-weight: 900;
  margin-bottom: 15px;
  color: #000000;
  text-transform: uppercase;
}
.prodList ul li .prodText .proSummary {
  font-size: 15px;
  color: #000000;
  font-weight: 400;
  margin-bottom: 15px;
}
.prodList ul li .prodText .LinkStyle a {
  display: inline-block;
}
.prodList ul li:nth-child(2n) {
  direction: rtl;
}
.prodList ul li:nth-child(2n) .prodImg {
  margin-right: 0;
  margin-left: 5%;
}
.prodList ul li:nth-child(2n) .prodImg::after {
  right: auto;
  left: 0;
}
.prodList ul li:last-child {
  margin-bottom: 0;
}

.hpBlackTitle {
  color: #000000 !important;
}

.factsList ul {
  text-align: center;
}
.factsList ul li {
  display: inline-block;
  margin: 0 15px;
  margin-bottom: 15px;
}
.factsList ul li .numberHolder {
  width: 150px;
  height: 150px;
  background-color: #fe6a20;
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 40px;
  color: #ffffff;
  font-weight: 700;
}
.factsList ul li .numberText {
  display: inline-block;
  vertical-align: middle;
  margin-left: 15px;
  color: #ffffff;
  font-size: 22px;
  width: 150px;
  text-align: left;
  font-weight: 300;
}

.footer {
  padding: 50px 0;
  font-size: 0;
  background-color: #f2f2f2;
}
.footer .footerCol {
  width: 28%;
  margin-right: 8%;
  display: inline-block;
}
.footer .footerCol:nth-child(2) {
  width: 27%;
}
.footer .footerCol:last-child {
  margin-right: 0;
}
.footer .footerCol h3 {
  color: #697c89;
}
.footer .footerCol .addressText {
  color: #000000;
  font-size: 14px;
  margin-bottom: 20px;
}
.footer .footerCol .addressText a {
  color: #000000;
  text-decoration: underline;
}
.footer .footerCol .contactInfo li a {
  color: #000000;
  font-size: 14px;
  display: block;
  padding: 5px 0 4px 25px;
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 15px auto;
}
.footer .footerCol .contactInfo li a:hover {
  text-decoration: underline;
}
.footer .footerCol .contactInfo li a.callIcon {
  background-image: url(../images/call-icon.svg);
}
.footer .footerCol .contactInfo li a.mailIcon {
  background-image: url(../images/mail-icon.svg);
}
.footer .footerCol .smLink li {
  margin-right: 10px;
  display: inline-block;
}
.footer .footerCol .smLink li a {
  display: block;
  width: 55px;
  height: 55px;
  background-size: 100% auto;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #fe6a20;
  border-radius: 50%;
  transition: all cubic-bezier(0.53, 0.39, 0.12, 0.81) 200ms;
}
.footer .footerCol .smLink li a:hover {
  background-color: #697c89;
}
.footer .footerCol .smLink li a.fbIcon {
  background-image: url(../images/fb-icon.svg);
}
.footer .footerCol .smLink li a.instaIcon {
  background-image: url(../images/insta-icon.svg);
}

.footerCp {
  padding: 15px 0;
  background-color: #ffffff;
}
.footerCp .cpHolder {
  float: left;
  width: 50%;
}
.footerCp .desDev {
  float: right;
  width: 50%;
  text-align: right;
}
.footerCp .desDev a {
  color: #fe6a20;
}

.map img, .map iframe {
  width: 100%;
  display: block;
}

.withLeftTitle h3, .withLeftTitle h2 {
  font-size: 25px;
  margin-bottom: 20px;
  padding-bottom: 10px;
  position: relative;
  text-transform: uppercase;
}
.withLeftTitle h3::after, .withLeftTitle h2::after {
  content: "";
  width: 70px;
  height: 3px;
  background-color: #fe6a20;
  position: absolute;
  left: 0;
  bottom: 0;
}

.isoLogo {
  width: 100px;
  margin-top: 20px;
}
.isoLogo img {
  width: 100%;
}

.greyBg {
  background-color: #697c89 !important;
}
.greyBg .smallText {
  color: #ffffff;
}
.greyBg h2 {
  color: #ffffff !important;
}
.greyBg .blockText {
  color: #ffffff;
}

.header {
  box-shadow: -4px 8px 10px -1px rgba(224, 224, 224, 0.35);
}
.header .logoHolder {
  width: 110px;
  padding: 25px 0;
  float: left;
}
.header .menuHolder {
  float: right;
}
.header .menuHolder ul li {
  display: inline-block;
  margin-left: 25px;
}
.header .menuHolder ul li a {
  padding: 46px 0;
  display: block;
  font-size: 17px;
  font-weight: 500;
  color: #000000;
  border-bottom: 15px solid transparent;
}
.header .menuHolder ul li a.selected {
  border-color: #fe6a20;
}

.subpageBanner {
  padding: 100px 0;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.subpageBanner .breadcrumbs {
  margin-bottom: 10px;
}
.subpageBanner .breadcrumbs ul li {
  display: inline-block;
  font-size: 15px;
}
.subpageBanner .breadcrumbs ul li a {
  color: #fe6a20;
}
.subpageBanner .breadcrumbs ul li a:hover {
  color: #ffffff;
}
.subpageBanner h1 {
  font-size: 55px;
  color: #ffffff;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-weight: 800;
}
.subpageBanner .bannerSummary {
  font-size: 18px;
  font-weight: 300;
  color: #ffffff;
  line-height: 27px;
  width: 50%;
}

.prodHolder {
  padding: 50px 0;
  font-size: 0;
}
.prodHolder .breadcrumbs {
  margin-bottom: 10px;
  font-size: 15px;
}
.prodHolder .breadcrumbs ul li {
  display: inline-block;
  font-size: 15px;
}
.prodHolder .breadcrumbs ul li a {
  color: #fe6a20;
}
.prodHolder .breadcrumbs ul li a:hover {
  color: #ffffff;
}
.prodHolder h1 {
  font-size: 35px;
  color: #fe6a20;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-weight: 800;
}
.prodHolder .prodImg {
  display: inline-block;
  vertical-align: middle;
  width: 40%;
  margin-right: 5%;
}
.prodHolder .prodImg img {
  width: 100%;
  display: block;
}
.prodHolder .prodInfo {
  display: inline-block;
  vertical-align: middle;
  width: 55%;
}
.prodHolder .bannerSummary {
  font-size: 18px;
  font-weight: 300;
  color: #000;
  line-height: 27px;
  margin-bottom: 20px;
}
.prodHolder .LinkStyle a {
  display: inline-block;
}

.subpageBody .spBlock {
  background-color: #f2f2f2;
  padding: 80px 0;
}
.subpageBody .spBlock h2 {
  font-size: 32px;
  color: #000000;
}
.subpageBody .spBlock h4 {
  font-size: 18px;
  margin-bottom: 10px;
  color: #000;
}
.subpageBody .spBlock .blockContent {
  font-size: 16px;
  color: #000000;
  line-height: 30px;
}
.subpageBody .spBlock .blockContent .leftBox {
  float: left;
  width: 50%;
  margin-right: 10%;
}
.subpageBody .spBlock .blockContent .rightBox {
  float: right;
  width: 40%;
}

.quoteHolder {
  background-color: #fe6a20;
  padding: 80px 0;
  background-image: url(../images/machine-shape.svg);
  background-position: 90% center;
  background-repeat: no-repeat;
  background-size: auto 700px;
  background-attachment: fixed;
}
.quoteHolder h4 {
  font-size: 100px;
  font-weight: 900;
  color: #ffffff;
}

.prodimg {
  margin-bottom: 15px;
}

.clientsList ul {
  width: 100%;
  display: inline-block;
}
.clientsList ul li {
  width: 16%;
  margin-right: 0.8%;
  margin-bottom: 20px;
  float: left;
}
.clientsList ul li:nth-child(6n) {
  margin-right: 0;
}

.imgHolder img {
  width: 100%;
  display: block;
}

.spBlock {
  padding: 80px 0;
}
.spBlock h2 {
  color: #000000;
}
.spBlock .subTitleText {
  color: #000000;
  font-size: 16px;
  margin-bottom: 25px;
}

.smallPadBot {
  padding-bottom: 60px !important;
}

.LinkStyle a {
  color: #fe6a20;
  font-size: 16px;
  font-weight: 400;
  border: 1px solid #fe6a20;
  padding: 10px 15px;
  border-radius: 4px;
  transition: all cubic-bezier(0.53, 0.39, 0.12, 0.81) 200ms;
}
.LinkStyle a:nth-child(2) {
  margin-left: 15px;
  background-color: #fe6a20;
  color: #ffffff;
}
.LinkStyle a:hover {
  background-color: #697c89;
  color: #ffffff;
  border-color: #697c89;
}
.LinkStyle.fullSolidColor a {
  background-color: #fe6a20;
  color: #ffffff;
  display: inline-block;
}

.colBlock {
  width: 47%;
  margin-right: 6%;
  float: left;
  color: #ffffff;
  font-weight: 300;
  line-height: 22px;
}
.colBlock h3 {
  margin-bottom: 15px;
  font-size: 20px;
}
.colBlock:nth-child(2n) {
  margin-right: 0;
}

.spcGrid .LinkStyle {
  float: right;
  margin-top: 15px;
}
.spcGrid .LinkStyle a:hover {
  background-color: #697c89;
}
.spcGrid tr:nth-child(1) th {
  background-color: #fe6a20;
  font-size: 17px;
  font-weight: 500;
  color: #ffffff;
  text-align: var();
  height: 45px;
  vertical-align: bottom;
  padding: 0 0 9px 0;
}
.spcGrid tr:nth-child(1) th:nth-child(1) {
  background-color: transparent;
}
.spcGrid tr th:nth-child(1) {
  width: 200px;
  text-align: right;
  padding: 9px 4px;
}
.spcGrid tr td {
  text-align: center;
  padding: 9px 4px;
}
.spcGrid tr:nth-child(2n) td, .spcGrid tr:nth-child(2n) th {
  background-color: #f9f9f9;
}

.legend {
  font-size: 12px;
  margin-top: 15px;
  float: left;
}

.inputRow {
  margin-bottom: 12px;
}

.labelHolder {
  margin-bottom: 7px;
}
.labelHolder label {
  font-size: 15px;
  color: #000000;
  font-weight: 500;
}

.inputHolder input, .inputHolder textarea {
  width: 100%;
  padding: 10px;
  -webkit-appearance: none;
  border: 1px solid #cccccc;
  border-radius: 3px;
}
.inputHolder textarea {
  height: 100px;
}

.submitHolder {
  text-align: right;
}
.submitHolder input {
  -webkit-appearance: none;
  background-color: #fe6a20;
  padding: 10px 15px;
  color: #ffffff;
  border: 0 none;
  margin-left: 10px;
  cursor: pointer;
  transition: all cubic-bezier(0.53, 0.39, 0.12, 0.81) 200ms;
}
.submitHolder input:hover {
  background-color: #697c89;
}
.submitHolder a {
  color: #697c89;
}
.submitHolder a:hover {
  color: #fe6a20;
}/*# sourceMappingURL=styles.css.map */