@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700;900&display=swap');
$orange: #fe6a20;
$grey: #697c89;
$transition200: all cubic-bezier(.53,.39,.12,.81) 200ms;
$transition200Delay: all cubic-bezier(.53,.39,.12,.81) 300ms 600ms;
$transitionMenu:  all 500ms cubic-bezier(.93,.1,.42,.91);
/************************************* Reset Styles ********************************/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	vertical-align: baseline;
}
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
	display: block;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after, q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
td,div{
	vertical-align:top;
}
*{
	outline:none;
}
li,ol{
	list-style-type:none;
	list-style-image:none;
	list-style:none;
}
img{
	border:0px;
}
textarea {
    resize: none;
}
input:focus,select:focus,textarea:focus,button:focus {
    outline: none;
}
a{
	text-decoration:none;
	}
input, textarea, select, body{
	font-size:14px;
	font-family: 'Montserrat', sans-serif;
	font-weight: 400;
}
.safeArea{
	max-width: 1350px;
	margin: 0 auto;
}
.clearBoth{
	clear: both;
}
/************************************* Reset End Styles ********************************/
.headerBar{
	background-color: $orange;
	.safeArea{
		position: relative;
	}
	.leftCol{
		width: 50%;
		text-align: left;
		float: left;
		padding: 15px 25px;
		color: #ffffff;
		font-size: 14px;
	}
	.requestCallBack{
		width: 50%;
		text-align: right;
		float: right;
		a{
			padding: 15px 25px;
			display: inline-block;
			background-color: #ffffff;
			color: $grey;
			font-weight: 500;
			font-size: 15px;
			transition: $transition200;
			&:hover{
				background-color: $orange;
				color: #ffffff;
			}
		}
	}
}
.callbackPopup{
	position: absolute;
	right: 0;
	top: 100%;
	width: 40%;
	background-color: #ffffff;
	display: none;
	.popTitle{
		padding: 15px;
		font-size: 20px;
		font-weight: 500;
		color: #000000;
		border-bottom: 1px solid #000000;
		position: relative;
		display: none;
		.close{
			width: 30px;
			height: 30px;
			float: right;
			img{
				width: 100%;
			}
		}
	}
	.popBody{
		padding: 15px;
	}
}
.mainBannerHolder{
	text-align: center;
	background-position: right center;
	background-repeat: no-repeat;
	background-size: cover;
	padding: 80px 0;
	.logoHolder{
		width: 180px;
		display: inline-block;
		margin-bottom: 50px;
	}
	.captions{
		.smallText{
			font-size: 22px;
			font-weight: 300;
			margin-bottom: 15px;
			color: #ffffff;
			text-transform: uppercase;
		}
		.bigText{
			font-size: 46px;
			font-weight: 900;
			color: #ffffff;
			text-transform: uppercase;
			margin-bottom: 25px;
			span{
				color: $orange;
			}
		}
	}


}
.hpBlock{
	padding: 80px 0;
	background-color: #f2f2f2;
	.blockTitle{
		text-align: center;
		margin-bottom: 55px;
		.smallText{
			font-size: 19px;
			color: #ffffff;
			font-weight: 400;
			margin-bottom: 10px;
			text-transform: uppercase;
		}
		h2{
			font-size: 30px;
			color: #000;
			font-weight: 900;
			margin-bottom: 25px;
			position: relative;
			padding-bottom: 15px;
			text-transform: uppercase;
			span{
				color: $orange;
			}
			&::after{
				content: "";
				width: 150px;
				height: 3px;
				background-color: $orange;
				position:absolute;
				left: 50%;
				transform: translateX(-50%);
				bottom: 0;
			}
		}
		.blockText{
			width: 700px;
			margin: 0 auto;
		}
	}
}
.prodList{
	ul{
		li{
			font-size: 0;
			margin-bottom: 40px;
			.prodImg{
				width: 45%;
				display: inline-block;
				vertical-align: middle;
				margin-right: 5%;
				position: relative;
				&::after{
					content: "";
					width: 100px;
					height: 100px;
					position: absolute;
					right: 0;
					bottom: 0;
					background-image: url(../images/pattern-1.svg);
					background-repeat: no-repeat;
					background-size: 100% auto;
					z-index: 1;
				}
				img{
					width: 100%;
					position: relative;
					z-index: 2;
				}
			}
			.prodText{
				width: 50%;
				display: inline-block;
				vertical-align: middle;
				h3{
					font-size: 24px;
					font-weight: 900;
					margin-bottom: 15px;
					color: #000000;
					text-transform: uppercase;
				}
				.proSummary{
					font-size: 15px;
					color: #000000;
					font-weight: 400;
					margin-bottom: 15px;
				}
				.LinkStyle{
					a{
						display: inline-block;
					}
				}
			}
			&:nth-child(2n){
				direction: rtl;
				.prodImg{
					margin-right: 0;
					margin-left: 5%;
					&::after{
						right: auto;
						left: 0;
					}
				}
			}
			&:last-child{
				margin-bottom:0
			}
		}
	}
}
.hpBlackTitle{
	color: #000000 !important;
}
.factsList{
	ul{
		text-align: center;
		li{
			display: inline-block;
			margin: 0 15px;
			margin-bottom: 15px;
			.numberHolder{
				width: 150px;
				height: 150px;
				background-color: $orange;
				display: inline-flex;
				vertical-align: middle;
				align-items: center;
				justify-content: center;
				border-radius: 50%;
				font-size: 40px;
				color: #ffffff;
				font-weight: 700;
			}
			.numberText{
				display: inline-block;
				vertical-align: middle;
				margin-left: 15px;
				color: #ffffff;
				font-size: 22px;
				width: 150px;
				text-align: left;
				font-weight: 300;
			}
		}
	}
}
.footer{
	padding: 50px 0;
	font-size: 0;
	background-color: #f2f2f2;
	.footerCol{
		width: 28%;
		margin-right: 8%;
		display: inline-block;
		&:nth-child(2){
			width: 27%;
		}
		&:last-child{
			margin-right: 0;
		}
		h3{
			color: $grey;
		}
		.addressText{
			color: #000000;
			font-size: 14px;
			margin-bottom: 20px;
			a{
				color: #000000;
				text-decoration: underline;
			}
		}
		.contactInfo{
			li{
				a{
					color: #000000;
					font-size: 14px;
					display: block;
					padding:5px 0 4px 25px;
					background-position: left center;
					background-repeat: no-repeat;
					background-size: 15px auto;
					&:hover{
						text-decoration: underline;
					}
					&.callIcon{
						background-image: url(../images/call-icon.svg);
					}
					&.mailIcon{
						background-image: url(../images/mail-icon.svg);
					}
				}
			}
		}
		.smLink{
			li{
				margin-right: 10px;
				display: inline-block;
				a{
					display: block;
					width: 55px;
					height: 55px;
					background-size: 100% auto;
					background-position: center center;
					background-repeat: no-repeat;
					background-color: $orange;
					border-radius: 50%;
					transition: $transition200;
					&:hover{
						background-color: $grey;
					}
					&.fbIcon{
						background-image: url(../images/fb-icon.svg);
					}
					&.instaIcon{
						background-image: url(../images/insta-icon.svg)
					}
				}
			}
		}
	}
}
.footerCp{
	padding: 15px 0;
	background-color: #ffffff;
	.cpHolder{
		float: left;
		width: 50%;
	}
	.desDev{
		float: right;
		width: 50%;
		text-align: right;
		a{
			color: $orange;
		}
	}
}
.map{
	img,iframe{
		width: 100%;
		display: block;
	}
}
.withLeftTitle{
	h3,h2{
		font-size: 25px;
		margin-bottom: 20px;
		padding-bottom: 10px;
		position: relative;
		text-transform: uppercase;
		&::after{
			content: "";
			width: 70px;
			height: 3px;
			background-color: $orange;
			position: absolute;
			left: 0;
			bottom: 0;
		}
	}
}
.isoLogo {
	width: 100px;
	margin-top: 20px;
	img{
		width: 100%;
	}
}
.greyBg{
	background-color: $grey !important;
	.smallText{
		color: #ffffff;
	}
	h2{
		color: #ffffff !important;
	}
	.blockText{
		color: #ffffff;
	}
}
.header{
	box-shadow: -4px 8px 10px -1px rgba(224,224,224,0.35);
	.logoHolder{
		width: 110px;
		padding: 25px 0;
		float: left;
	}
	.menuHolder{
		float: right;
		ul{
			li{
				display: inline-block;
				margin-left: 25px;
				a{
					padding: 46px 0;
					display: block;
					font-size: 17px;
					font-weight: 500;
					color: #000000;
					border-bottom: 15px solid transparent;
					&.selected{
						border-color: $orange;
					}
				}
			}
		}
	}
}
.subpageBanner{
	padding: 100px 0;
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
	.breadcrumbs{
		margin-bottom: 10px;
		ul{
			li{
				display: inline-block;
				font-size: 15px;
				a{
					color: $orange;
					&:hover{
						color: #ffffff;
					}
				}
			}
		}
	}
	h1{
		font-size: 55px;
		color: #ffffff;
		text-transform: uppercase;
		margin-bottom: 10px;
		font-weight: 800;
	}
	.bannerSummary{
		font-size: 18px;
		font-weight: 300;
		color: #ffffff;
		line-height: 27px;
		width: 50%;
	}
}
.prodHolder{
	padding: 50px 0;
	font-size: 0;
	.breadcrumbs{
		margin-bottom: 10px;
		font-size: 15px;
		ul{
			li{
				display: inline-block;
				font-size: 15px;
				a{
					color: $orange;
					&:hover{
						color: #ffffff;
					}
				}
			}
		}
	}
	h1{
		font-size: 35px;
		color: $orange;
		text-transform: uppercase;
		margin-bottom: 10px;
		font-weight: 800;
	}
	.prodImg{
		display: inline-block;
		vertical-align: middle;
		width: 40%;
		margin-right: 5%;
		img{
			width: 100%;
			display: block;
		}
	}
	.prodInfo{
		display: inline-block;
		vertical-align: middle;
		width: 55%;
	}
	.bannerSummary{
		font-size: 18px;
		font-weight: 300;
		color: #000;
		line-height: 27px;
		margin-bottom: 20px;
	}
	.LinkStyle{
		a{
			display: inline-block;
		}
	}
}
.subpageBody{
	.spBlock{
		background-color: #f2f2f2;
		padding: 80px 0;
		h2{
			font-size: 32px;
			color: #000000;
		}
		h4{
			font-size: 18px;
			margin-bottom: 10px;
			color: #000;
		}
		.blockContent{
			font-size: 16px;
			color: #000000;
			line-height: 30px;
			.leftBox{
				float: left;
				width: 50%;
				margin-right: 10%;
			}
			.rightBox{
				float: right;
				width: 40%;
				
			}
		}
	}
	
	
}
.quoteHolder{
	background-color: $orange;
	padding: 80px 0;
		background-image: url(../images/machine-shape.svg);
		background-position: 90% center;
		background-repeat: no-repeat;
		background-size: auto 700px;
		background-attachment: fixed;
	h4{
		font-size: 100px;
		font-weight: 900;
		color: #ffffff;
	}
}

.prodimg{
	margin-bottom: 15px;
}

.clientsList{
	ul{
		width: 100%;
		display: inline-block;
		li{
			width: 16%;
			margin-right: 0.8%;
			margin-bottom: 20px;
			float: left;
			&:nth-child(6n){
				margin-right: 0;
			}
		}
	}
}

.imgHolder{
	img{
		width: 100%;
		display: block;
	}
}
.spBlock{
	padding: 80px 0;
	h2{
		color: #000000;
	}
	.subTitleText{
		color: #000000;
		font-size: 16px;
		margin-bottom: 25px;
	}
}
.smallPadBot{
	padding-bottom: 60px !important;
}
.LinkStyle{
	a{
		color: $orange;
		font-size: 16px;
		font-weight: 400;
		border: 1px solid $orange;
		padding: 10px 15px;
		border-radius: 4px;
		transition: $transition200;
		&:nth-child(2){
			margin-left: 15px;
			background-color: $orange;
			color: #ffffff;
		}
		&:hover{
			background-color: $grey;
			color: #ffffff;
			border-color: $grey;
		}
	}
	&.fullSolidColor{
		a{
			background-color: $orange;
			color: #ffffff;
			display: inline-block;
			}
	}
}
.colBlock{
	width: 47%;
	margin-right: 6%;
	float: left;
	color: #ffffff;
	font-weight: 300;
	line-height: 22px;
	h3{
		margin-bottom: 15px;
		font-size: 20px;
	}
	&:nth-child(2n){
		margin-right: 0;
	}
}

.spcGrid{
	.LinkStyle{
		float: right;
		margin-top: 15px;
		a{
			&:hover{
				background-color: $grey;
			}
		}
	}
	tr:nth-child(1){
		th{
			background-color: $orange;
			font-size: 17px;
			font-weight: 500;
			color: #ffffff;
			text-align: var();
			height: 45px;
			vertical-align: bottom;
			padding: 0 0 9px 0;
		}
	}
	tr:nth-child(1){
		th:nth-child(1){
			background-color: transparent;
		}
	}
	tr{
		th:nth-child(1){
			width: 200px;
			text-align: right;
			padding: 9px 4px;
		}
	}
	tr{
		td{
			text-align: center;
			padding: 9px 4px;
		}
	}
	tr:nth-child(2n){
		td, th{
			background-color: #f9f9f9;
		}
	}
	
}

.legend{
	font-size: 12px;
	margin-top: 15px;
	float: left;
}

.inputRow{
	margin-bottom: 12px;
}
.labelHolder{
	margin-bottom: 7px;
	label{
		font-size: 15px;
		color: #000000;
		font-weight: 500;
	}
}
.inputHolder{
	input, textarea{
		width: 100%;
		padding: 10px;
		-webkit-appearance: none;
		border: 1px solid #cccccc;
		border-radius: 3px;
	}
	textarea{
		height: 100px;
	}
}
.submitHolder{
	text-align: right;
	input{
		-webkit-appearance: none;
		background-color: $orange;
		padding: 10px 15px;
		color: #ffffff;
		border: 0 none;
		margin-left: 10px;
		cursor: pointer;
		transition: $transition200;
		&:hover{
			background-color: $grey;
		}
	}
	a{
		color: $grey;
		&:hover{
			color: $orange;
		}
	}
}