@media all and (min-width: 100px) and (max-width: 1020px){
    .hpBlock{
        padding: 40px 0;
    }
    .safeArea{
        max-width: 100%;
        padding: 0 40px;
        box-sizing: border-box;
    }
    .headerBar .safeArea{
        padding: 0;
    }
    .factsList ul li{
        width: 33%;
        margin: 0;
        text-align: center;
    }
    .factsList ul li .numberText{
        margin-left: 0;
        text-align: center;
        margin-top: 10px;
    }
    .factsList ul li .numberHolder{
        width: 120px;
        height: 120px;
        font-size: 40px;
    }
    .subpageBanner{
        padding: 70px 0;
    }
    .subpageBanner .bannerSummary{
        width: 100%;
    }
    .subpageBanner h1{
        font-size: 45px;
    }
    .subpageBody .spBlock{
        padding: 50px 0;
    }
    .subpageBody .spBlock .blockContent .leftBox, .subpageBody .spBlock .blockContent .rightBox{
        width: 100%;
        float: none;
    }
    .subpageBody .spBlock .blockContent .rightBox{
        margin-top: 30px;
    }
    .quoteHolder{
        background-size: auto 300px;
    }
    .quoteHolder h4{
        font-size: 50px;
    }
}

@media all and (min-width: 100px) and (max-width: 750px){
	.headerBar .leftCol{
        width: 75%;
        box-sizing: border-box;
        padding: 10px 15px; 
        font-size: 12px;
    }
    .headerBar .requestCallBack{
        width: 25%;
    }
    .headerBar .requestCallBack a{
        font-size: 12px;
        padding: 10px;
        display: block;
        text-align: center;
    }
    .callbackPopup{
        right: auto;
        left: 0;
        width: 100%;
    }
    .mainBannerHolder{
        padding: 50px;
    }
    .mainBannerHolder .logoHolder{
        width: 140px;
    }
    .mainBannerHolder .captions .bigText{
        font-size: 30px;
    }
    .mainBannerHolder .captions .smallText{
        font-size: 18px;
    }
    .LinkStyle a{
        display: inline-block;
    }
    .hpBlock .blockTitle h2{
        font-size: 25px;
    }
    .hpBlock .blockTitle .smallText{
        font-size: 17px;
    }
    .hpBlock .blockTitle .blockText{
        width: 100%;
    }
    .safeArea{
        padding: 0 20px;
    }
    .prodList ul li .prodImg{
        width: 100%;
        float: none;
        margin-bottom: 15px;
        text-align: center;
    }
    .prodList ul li .prodImg img{
        width: 70%;
        display: inline-block;
    }
    .prodList ul li .prodText{
        width: 100%;
        float: none;
    }
    .factsList ul li .numberHolder{
        width: 80px;
        height: 80px;
        font-size: 24px;
    }
    .factsList ul li .numberText{
        font-size: 17px;
        width: 100%;
    }
    .footer .footerCol, .footer .footerCol:nth-child(2){
        width: 100%;
        margin-right: 0 !important;
    }
    .footer .footerCol:nth-child(2){
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .footer{
        padding: 25px 0;
    }
    .footer .footerCol h3{
        font-size: 17px;
    }
    .footer .footerCol .smLink li a{
        width: 40px;
        height: 40px;
    }
    .footerCp .cpHolder{
        width: 100%;
        float: none;
        text-align: center;
        margin-bottom: 10px;
        font-size: 12px;
    }
    .footerCp .desDev{
        width: 100%;
        float: none;
        font-size: 12px;
        text-align: center;
    }
    .header .menuHolder ul li{
        margin-left: 15px;
        
    }
    .header .menuHolder ul li a{
        font-size: 14px;
        border-width: 5px ;
        padding: 32px 0;
    }
    .header .logoHolder{
        width: 90px;
        padding: 11px 0;
    }
    .subpageBanner h1{
        font-size: 35px;
    }
    .subpageBanner .bannerSummary{
        font-size: 16px;
    }
    .subpageBody .spBlock h2{
        font-size: 25px;
    }
    .subpageBody .spBlock .blockContent{
        font-size: 14px;
        line-height: 25px;
    }
    .clientsList ul li{
        width: 25%;
    }
    .clientsList ul li a img{
        width: 100%;
        display: block;
    }

    .prodHolder .prodImg{
        width: 100%;
        margin-right: 0;
        margin-bottom: 15px;
    }
    .prodHolder .prodInfo{
        width: 100%;
    }
    .prodHolder h1{
        font-size: 25px;
    }
    .prodHolder .breadcrumbs ul li{
        font-size: 12px;
    }
    .prodHolder .bannerSummary{
        font-size: 15px;
        line-height: 20px;
    }
    .colBlock{
        width: 100%;
        margin-right: 0;
    }
    .colBlock:nth-child(2n){
        margin-top: 25px;
    }
    .spBlock{
        padding: 40px 0;
    }
    .withLeftTitle h3, .withLeftTitle h2{
        font-size: 18px;
    }
    .spBlock .subTitleText{
        font-size: 14px;
    }
    .spcGrid > span{
        max-width: 100%;
        overflow-x: scroll;
        display: block;
    }
    .spcGrid tr th:nth-child(1){
        min-width: 100px;
    }
    .spcGrid tr:nth-child(1) th{
        font-size: 14px;
    }
    .spcGrid tr td{
        font-size: 12px;
    }
    .LinkStyle a{
        font-size: 14px;
    }
    .footer .footerCol .addressText{
        font-size: 12px;
    }

}