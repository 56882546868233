/********************************************************************Common css***********************************************/
/************************************* Reset Styles ********************************/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	vertical-align: baseline;
}
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
	display: block;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after, q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
td,div{
	vertical-align:top;
}
*{
	outline:none;
}
li,ol{
	list-style-type:none;
	list-style-image:none;
	list-style:none;
}
img{
	border:0px;
}
textarea {
    resize: none;
}
input:focus,select:focus,textarea:focus,button:focus {
    outline: none;
}
a{
	text-decoration:none;
	}
input, textarea, select, body{
	font-size:14px;
	/* font-family:Gotham, "Helvetica Neue", Helvetica, Arial, sans-serif; */
	}
/************************************* Reset End Styles ********************************/
/**************************************Grid styles*****************************/
.container {
	margin:0 auto;
}
*, *:after, *:before{
	box-sizing:border-box;
	}
@media (max-width:768px) {
.container {
	margin:0 10px;
}
}
@media (min-width:768px) {
.container {
	width: 750px
}
}

@media (min-width:992px) {
.container {
	width: 970px
}
}

@media (min-width:1200px) {
.container {
	width: 1140px;
}
}
.container-fluid {
	margin-right: auto;
	margin-left: auto;
	padding-left: 15px;
	padding-right: 15px
}
.row {
	margin-left: -15px;
	margin-right: -15px
}
.col-xs-12, .col-sm-12, .col-md-12, .col-lg-12{
	clear:both;
	}
.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
	position: relative;
	min-height: 1px;
	padding-left: 15px;
	padding-right: 15px
}
.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
	float: left
}
.col-xs-12 {
	width: 100%
}
.col-xs-11 {
	width: 91.66666667%
}
.col-xs-10 {
	width: 83.33333333%
}
.col-xs-9 {
	width: 75%
}
.col-xs-8 {
	width: 66.66666667%
}
.col-xs-7 {
	width: 58.33333333%
}
.col-xs-6 {
	width: 50%
}
.col-xs-5 {
	width: 41.66666667%
}
.col-xs-4 {
	width: 33.33333333%
}
.col-xs-3 {
	width: 25%
}
.col-xs-2 {
	width: 16.66666667%
}
.col-xs-1 {
	width: 8.33333333%
}

@media (min-width:768px) {
.col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
	float: left
}
.col-sm-12 {
	width: 100%
}
.col-sm-11 {
	width: 91.66666667%
}
.col-sm-10 {
	width: 83.33333333%
}
.col-sm-9 {
	width: 75%
}
.col-sm-8 {
	width: 66.66666667%
}
.col-sm-7 {
	width: 58.33333333%
}
.col-sm-6 {
	width: 50%
}
.col-sm-5 {
	width: 41.66666667%
}
.col-sm-4 {
	width: 33.33333333%
}
.col-sm-3 {
	width: 25%
}
.col-sm-2 {
	width: 16.66666667%
}
.col-sm-1 {
	width: 8.33333333%
}
}

@media (min-width:992px) {
.col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
	float: left
}
.col-md-12 {
	width: 100%
}
.col-md-11 {
	width: 91.66666667%
}
.col-md-10 {
	width: 83.33333333%
}
.col-md-9 {
	width: 75%
}
.col-md-8 {
	width: 66.66666667%
}
.col-md-7 {
	width: 58.33333333%
}
.col-md-6 {
	width: 50%
}
.col-md-5 {
	width: 41.66666667%
}
.col-md-4 {
	width: 33.33333333%
}
.col-md-3 {
	width: 25%
}
.col-md-2 {
	width: 16.66666667%
}
.col-md-1 {
	width: 8.33333333%
}
}

@media (min-width:1200px) {
.col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
	float: left
}
.col-lg-12 {
	width: 100%
}
.col-lg-11 {
	width: 91.66666667%
}
.col-lg-10 {
	width: 83.33333333%
}
.col-lg-9 {
	width: 75%
}
.col-lg-8 {
	width: 66.66666667%
}
.col-lg-7 {
	width: 58.33333333%
}
.col-lg-6 {
	width: 50%
}
.col-lg-5 {
	width: 41.66666667%
}
.col-lg-4 {
	width: 33.33333333%
}
.col-lg-3 {
	width: 25%
}
.col-lg-2 {
	width: 16.66666667%
}
.col-lg-1 {
	width: 8.33333333%
}
}
.clearfix:before, .clearfix:after, .container:before, .container:after, .container-fluid:before, .container-fluid:after, .row:before, .row:after, .clearContainer:after {
	content: " ";
	display: table
}
.clearfix:after, .container:after, .container-fluid:after, .row:after, .clearContainer:after {
	clear: both
}
/**************************************Grid End Styles*****************************/
/************************************* Common Styles ********************************/
.rightAlign{
	text-align:right;
	}
.centerAligned{
	text-align:center;
	}
.centeredBlock{
	margin-left:auto;
	margin-right:auto;
	float:none;
	}
.fullWidth{
	width:100%;
}
.floatLeft{
	float:left;
}
.floatRight{
	float:right;
}
.posRelative{
	position:relative;
}
.absolutePos{
	position:absolute;
}
.textDecorationNone{
	text-decoration:none;
}
.textDecorationUnderline{
	text-decoration:underline;
}
.fontWeightBold{
	font-weight:bold;
}
.clearBoth{
	clear:both;
}
.clearRight{
	clear:right;
	}
input, textarea, select, body, .smallTop a, .mainMenu a{
	color:#868686;
}
.showFade{
	transition:all linear 400ms;
	-moz-transition:all linear 400ms;
	-webkit-transition:all linear 400ms;
	-ms-transition:all linear 400ms;
	}
/************************************* Common End Styles ********************************/
/***********Dropdown Styles************/
.dropDownImage{
	float:left;
	margin-right:10px;
	margin-top:3px;
	}
.dropDownImage img{
	display:block;
	}
.dropDownImage + .dropDownText{
	padding-left:27px;
	display:block;
	}
.dropDownToggle{
	position:relative;
	padding-right:14px;
	}
.dropDown{
	display:inline-block;
	position:relative;
	text-align:left;
	}
.dropDownToggle{
	display:block;
	}
.dropDownMenu{
	display:none;
	position:absolute;
	top:100%;
	white-space:nowrap;
	z-index:111;
	}
.dropDownMenu li{
	}
.dropDownToggle:after{
	display:block;
	content:'';
	position:absolute;
	top:50%;
	right:0;
	}
.noPad{
	padding-left:0;
	padding-right:0;
	}
/***********Dropdown End Styles************/
/************************************* Website Styles ********************************/
